.nav {
    background-color: #333;
    color: #dcdcdc;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    position: fixed;
    height: 4rem;
    top: 0;
    position: relative;
    width: 100%;

    @media (max-width: 768px) {
        height: 3rem;
    }
}

.nav a {
    color: inherit;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 1rem;
}

.nav li {
    padding: 0;
    display: flex;
    align-items: center;
    height: 100%;
}

.nav li.active {
    background-color: #555;
}

.nav li:hover {
    background-color: #555;
}

.nav ul {
    height: 100%;
}

.hamburger {
    display: none;
    font-size: 2rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0 1rem;
    color: #dcdcdc;
}

.nav-links {
    display: flex;
    list-style: none;
    background-color: #333;
    height: 100%;
}

.nav-links li {
    display: flex;
}

@media (max-width: 768px) {
    .nav-links {
        display: none;
        top: 3rem;
        right: 0;
        margin-left: auto;
        position: absolute;
        align-items: flex-end;
    }

    .nav-links.open {
        display: flex;
        width: 100%;
    }

    .nav-links li {
        padding: 1rem 8px;
        width: 100%;
        height: 48px;
        background-color: #333;
    }

    .nav-links li:active {
        background-color: #555;
    }

    .hamburger {
        display: block;
    }
}
