.education {
    color: #dcdcdc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.education li {
    margin: 2rem 0;
    list-style: none;

    h5 {
        margin: 0.5rem 0;
        color: yellow;
    }
}
