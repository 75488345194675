* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Roboto Mono", monospace;
}

body {
    background-color: #555;
    overflow-y: scroll;
    position: relative;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 5rem;
    min-height: 100vh;
}

.subpage-title {
    color: #dcdcdc;
    margin: 1.5rem;
    display: none;
}

#profile-picture {
    border-radius: 50%;
    border: 0.2rem solid #333;
    max-width: 30%;
}

.site-title {
    font-size: 2rem;
    padding: 0 1rem !important;

    @media (max-width: 600px) {
        font-size: 1.5rem;
    }
}

.container {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    padding: 0 1rem;
}

@media (max-width: 768px) {
    #nav {
        height: 3rem;
    }
    body {
        margin-right: 0;
    }
    #profile-picture {
        max-width: 50%;
        margin-top: 4rem;
    }
}

.text-box {
    width: 800px;

    @media (max-width: 1063px) {
        width: 90%;
    }
}

.arrow-down {
    position: fixed;
    bottom: 1rem;
    left: 50%;
    cursor: pointer;
    /* background-color: aliceblue; */
    width: 2rem;
    height: 2rem;
    z-index: 1;
    margin-bottom: 90px;
    animation: upAndDown 1s infinite;
    color: #333;
}

@keyframes upAndDown {
    0%,
    100% {
        transform: translateX(-50%) translateY(0);
    }
    50% {
        transform: translateX(-50%) translateY(-10px);
    }
}

/* Custom scrollbar for WebKit browsers */
::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    background: #333;
}

::-webkit-scrollbar-thumb {
    background-color: #555;
    border-radius: 10px;
    border: 3px solid #333;
}

/* Custom scrollbar for Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #555 #333;
}
