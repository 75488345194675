footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: #999;
    bottom: 0;
    left: 0;
    height: 5rem;
    position: fixed;
    width: 100%;
    gap: 0.5rem;
}

.profile-icons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    /* background-color: red; */
    width: 100%;
}
