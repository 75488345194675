.home {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
}

.home h2 {
    color: #dcdcdc;
    margin-top: 2rem;
}

.welcome {
    margin-left: 5rem;
    color: yellow;
}

@media (max-width: 768px) {
    .home {
        margin-top: 0;
    }
    .welcome {
        margin: 0;
        text-align: center;
        padding: 0.5rem;
    }
}

@media (max-width: 992px) {
    .home {
        flex-direction: column;
    }
}
