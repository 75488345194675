.experience {
    color: #dcdcdc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.experience li {
    margin: 2rem 0;
    list-style: none;

    h6 {
        font-size: 0.8rem;
        margin: 1rem 0;
    }

    h5 {
        color: yellow;
    }
}
