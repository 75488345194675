.about {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about h2 {
    color: #dcdcdc;
    margin: 1.5rem;
    display: none;
}

.about p {
    margin: 2rem 0;
    color: #dcdcdc;
}
